import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';

// components
import Root from './Root';

const VCard = React.lazy(() => import('../pages/VCard-Dimas/index'));
const Vcard = React.lazy(() => import('../pages/VCard-Ivan/index'));

// lamding
const Landing = React.lazy(() => import('../pages/Landing'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  return useRoutes([
   { path: '/', element: <LoadComponent component={Landing} /> },
    // { path: '/Vcard', element: <LoadComponent component={VCard} /> },
    // { path: '/Vcard-', element: <LoadComponent component={Vcard} /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'Vcard',
          element:  <LoadComponent component={VCard} />,
        },
        {
          path: 'Vcard-',
          element: <LoadComponent component={Vcard} />,
        },
      ],
    },
  ]);
};

export { AllRoutes };